import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatThousands',
})
export class FormatThousandsPipe implements PipeTransform {
  transform(number: number): string {
    if (number) {
      let n = number % 1 == 0 ? 0 : 2;
      return parseFloat(String(number))
        .toFixed(n) // cantidad de decimales
        .replace('.', ',') // reemplaza . decimal por ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    } else {
      return '0';
    }
  }
}
